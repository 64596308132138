/*! easyWheel v1.2 | http://22codes.com/ | Released under Codecanyon Standard license : https://codecanyon.net/licenses/standard  */
.eWheel-wrapper,
.easyWheel {
  position: relative;
}
.easyWheel {
  max-width: 100%;
  margin: 4em auto 1em;
  font-size: 25px;
  font-weight: 700;
}
.easyWheel,
.easyWheel * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.easyWheel .eWheel-inner {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}
.easyWheel .eWheel {
  border-radius: 100%;
  overflow: hidden;
}
.easyWheel .eWheel,
.easyWheel .eWheel > .eWheel-bg-layer,
.easyWheel .eWheel > .eWheel-txt-wrap,
.easyWheel .eWheel > .eWheel-txt-wrap > .eWheel-txt {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.easyWheel .eWheel > .eWheel-bg-layer,
.easyWheel .eWheel > .eWheel-txt-wrap > .eWheel-txt {
  margin: 0 auto;
  border-radius: 100%;
  padding: 0;
  list-style: none;
  overflow: hidden;
  color: #ecf0f1;
}
.easyWheel .eWheel > .eWheel-bg-layer,
.easyWheel .eWheel > .eWheel-txt-wrap {
  transform: rotate(-90deg);
}
.easyWheel .eWheel .eWheel-child .eWheel-inside {
  display: table;
  transform: rotate(0) skew(-45deg);
  width: 50%;
  height: 50%;
  transform-origin: 0 0;
  text-align: right;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  overflow: hidden;
}
.easyWheel .eWheel .eWheel-child .eWheel-inside > div {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  transform: rotate(25deg);
  transform-origin: 115% 25%;
  padding-right: 40px;
  font-size: 18px;
  font-weight: 700;
}
.easyWheel .eWheel > .eWheel-bg-layer > div {
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  border: 1px solid transparent;
  background-color: #404040;
}
.easyWheel .eWheel > .eWheel-bg-layer > div:nth-child(odd) {
  background-color: #616161;
}
.easyWheel .eWheel > .eWheel-txt-wrap > .eWheel-txt > div {
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  line-height: 1.2em;
  max-height: 23.4em;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 1px;
  width: 50%;
  padding-right: 6%;
  font-weight: 700;
  font-size: 100%;
  cursor: default;
  color: #fff;
  text-align: right;
}
.easyWheel .eWheel-center {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  text-align: center;
}
.easyWheel .eWheel-center > .ew-center-empty,
.easyWheel .eWheel-center > .ew-center-html {
  max-width: 100%;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
}
.easyWheel .eWheel-center > .ew-center-empty {
  position: absolute;
}
.easyWheel .eWheel-center > img {
  max-width: 100%;
  width: 200px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.easyWheel .eWheel-center > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: 0 0 !important;
}
.easyWheel .eWheel-marker {
  border-spacing: 90px;
  width: 16%;
  height: 16%;
  position: absolute;
  left: 50%;
  top: -18%;
  margin-top: 7%;
  margin-left: -8%;
  transition: 0.2 rotate linear;
  z-index: 3;
  display: block;
  transform: rotate(0);
  transform-origin: 50% 35%;
}
.easyWheel .eWheel-marker > svg {
  height: 100%;
  display: block;
  text-align: center;
  margin: 0 auto;
}
.easyWheel .rotate {
  transform: rotate(100deg);
}
.eWheel > .eWheel-bg-layer > svg {
  margin: 0 auto;
  border-radius: 50%;
  display: block;
  width: 100%;
  height: 100%;
  transform: rotate(0);
}
.eWheel > .eWheel-txt-wrap > .eWheel-txt > div.ew-ccurrent {
  color: #cfd8dc;
}
