.pr- {
  &10 {
    padding-right: 10px;
  }

  &20 {
    padding-right: 20px;
  }

  &30 {
    padding-right: 30px;
  }
}

.pl- {
  &10 {
    padding-left: 10px;
  }

  &20 {
    padding-left: 20px;
  }

  &30 {
    padding-left: 30px;
  }
}

.pt- {
  &10 {
    padding-top: 10px;
  }

  &20 {
    padding-top: 20px;
  }

  &30 {
    padding-top: 30px;
  }
}

.pb- {
  &10 {
    padding-bottom: 10px;
  }

  &20 {
    padding-bottom: 20px;
  }

  &30 {
    padding-bottom: 30px;
  }

  &40 {
    padding-bottom: 40px;
  }
}
