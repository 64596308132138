.toast {
  opacity: 0.9;
}

.toast-title {
  font-size: 16px;
  margin-bottom: 5px;
}

.toast-message {
  font-size: 14px;
}

.toast-container.toast-top-full-width .toast {
  width: 100% !important;
  border-radius: 0;
}
