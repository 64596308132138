.btn {
  padding: 12px 30px;
  text-transform: uppercase;
  background-color: $default-detail-color;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 50px;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: 0.3s opacity;
    border-radius: 50px;
  }

  &:hover::after,
  &:disabled::after {
    opacity: 1;
  }

  &:hover::after {
    background-color: rgba(255, 255, 255, 0.3);
  }

  &:disabled {
    cursor: not-allowed;

    &::after {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
}

.btn-wide {
  width: 100%;
}

.btn-strong {
  font-weight: bold;
}

.btn-outline {
  background-color: transparent;
  border: 1px solid rgb(233, 108, 121);
  color: rgb(233, 108, 121);
}
