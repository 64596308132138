@use '@angular/material' as mat;
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.
@import 'src/assets/styles/theming';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$front-takeaway-front-primary: mat.define-palette($mat-primary, main, lighter, darker);
$front-takeaway-front-accent: mat.define-palette($mat-accent, main, lighter, darker);

// The warn palette is optional (defaults to red).
$front-takeaway-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$front-takeaway-theme: mat.define-light-theme($front-takeaway-front-primary, $front-takeaway-front-accent, $front-takeaway-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$front-takeaway-primary: mat.define-palette($mat-primary, main, lighter, darker);
$front-takeaway-accent: mat.define-palette($mat-accent, main, lighter, darker);

// The warn palette is optional (defaults to red).
$front-takeaway-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$front-takeaway-theme: mat.define-light-theme($front-takeaway-primary, $front-takeaway-accent, $front-takeaway-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($front-takeaway-theme);

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$front-takeaway-primary: mat.define-palette($mat-primary);
$front-takeaway-accent: mat.define-palette($mat-accent, A200, A100, A400);

// The warn palette is optional (defaults to red).
$front-takeaway-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$front-takeaway-theme: mat.define-light-theme($front-takeaway-primary, $front-takeaway-accent, $front-takeaway-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@import 'assets/styles/colors.scss';
@import 'assets/styles/buttons';
@import 'assets/styles/inputs';
@import 'assets/styles/margins';
@import 'assets/styles/toast';
@import 'assets/styles/headers';
@import 'assets/styles/text';
@import 'assets/styles/animations';
@import 'assets/styles/paddings';
@import 'assets/styles/material';

html,
body {
  height: 100vh !important;
  width: auto;
  overflow-x: hidden;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  font-size: 16px;

  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'],
  input[type='tel'] {
    font-size: 16px;
  }
}

body,
button {
  font-family: 'Roboto', sans-serif;
}

button {
  font-size: 15px;
}

textarea {
  font-family: 'Roboto', sans-serif;
}

h1,
h2,
h3,
h4 {
  margin-block-start: 0.3em;
  margin-block-end: 0.3em;
}

* {
  box-sizing: border-box;
}

.container-20 {
  padding: 20px;
}

.container-padding {
  padding: 10px 20px 20px 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

button:focus {
  outline: 0;
}

.gdpr-info {
  font-size: 11px;
  opacity: 0.6;
  width: 75%;
  max-width: 350px;
  text-align: center;
  display: inline-block;
  line-height: 14px;
}

.bold-text {
  font-weight: 500;
  color: black;
}

app-layout-header {
  background: inherit;
}

a {
  //color: $default-detail-color;
  text-decoration: none;
}

//.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
//  background: yellow;
//}

.cdk-overlay-backdrop {
  overflow: hidden;
}
