@import './colors';

input[type='text'].inp,
input[type='email'].inp,
input[type='number'].inp,
input[type='tel'].inp,
select {
  padding: 10px;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid $inputs-color;
}

textarea {
  border-radius: 4px;
  border: 1px solid $inputs-color;
  padding: 10px;
  resize: none;
}

input[type='checkbox'] {
  display: none;
}

input[type='checkbox'] + label {
  cursor: pointer;
}

input[type='checkbox'] + label:before {
  background: #fff;
  height: 18px;
  border: 2px solid $inputs-color;
  border-radius: 2px;
  content: ' ';
  display: inline-block;
  width: 18px;
  vertical-align: middle;
  margin-right: 10px;
  position: relative;
  top: -1px;
}

input[type='checkbox']:checked + label:before {
  content: '\2713';
  color: $inputs-color;
  font-size: 18px;
  text-align: center;
  line-height: 18px;
}

select {
  background-image: linear-gradient(45deg, transparent 50%, $inputs-color 50%), linear-gradient(135deg, $inputs-color 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  -webkit-appearance: none;
}

select:required:invalid {
  color: $inputs-color;
}
option[value=''][disabled] {
  display: none;
}
option {
  color: black;
}
