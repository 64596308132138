.mt- {
  &5 {
    margin-top: 5px;
  }

  &10 {
    margin-top: 10px;
  }

  &20 {
    margin-top: 20px;
  }

  &30 {
    margin-top: 30px;
  }

  &40 {
    margin-top: 40px;
  }
}

.mb- {
  &5 {
    margin-bottom: 5px;
  }

  &10 {
    margin-bottom: 10px;
  }

  &20 {
    margin-bottom: 20px;
  }

  &30 {
    margin-bottom: 30px;
  }

  &40 {
    margin-bottom: 40px;
  }
}

.mr- {
  &5 {
    margin-right: 5px;
  }

  &10 {
    margin-right: 10px;
  }

  &20 {
    margin-right: 20px;
  }

  &30 {
    margin-right: 30px;
  }
}
